import React from "react";
import "./home.css";
import Me from "../../assets/pic-1.png";
import ScrollDown from "./ScrollDown";
import 'animate.css';


const Home = () => {
  return (
    <section className="home container" id="home">
      <div className="intro">
        <img src={Me} alt="" className="home__img" />
        <h1 className="home__name animate__animated animate__bounceInLeft">Arish Soomar</h1>

        <a href="https://www.linkedin.com/in/arish-soomar-57b40520b/" target="_blank" className="btn animate__animated animate__bounceInLeft" id="home__btn">
          Contact Me
        </a>

        <ScrollDown />
      </div>

      
    </section>
  );
};

export default Home;
