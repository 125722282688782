const Data = [
  {
    id: 1,
    category: "education",
    icon: "icon-graduation",
    year: "2021 - present",
    title: "B.A: Computer Science",
    desc: "University of Minnesota - Twin Cities",
  },
  {
    id: 2,
    category: "education",
    icon: "icon-graduation",
    year: "2021 - present",
    title: "B.S.B: Finance",
    desc: "University of Minnesota: Carlson School of Management - Twin Cities ",
  },
  {
    id: 3,
    category: "education",
    icon: "icon-briefcase",
    year: "2018 - present",
    title: "Zero Robotics: MIT and NASA",
    desc: "Educator for the annual International Space Station Competition hosted by MIT and NASA.",
  },
  {
    id: 4,
    category: "experience",
    icon: "icon-briefcase",
    year: "June 2021 - October 2022",
    title: "Software Engineer Intern (DSI Consulting Group)",
    desc: "Led the development of user-friendly applications to automate client information processing through Anaplan Connect and Python.",
  },
  {
    id: 5,
    category: "experience",
    icon: "icon-briefcase",
    year: "September 2023 - January 2024",
    title: "Information Systems Teacher Assistant (University of Minnesota)",
    desc: "Assisted professors in preparing course materials, including lecture slides, assignments, and assessments, ensuring content accuracy and relevance.",
  },
  {
    id: 6,
    category: "experience",
    icon: "icon-briefcase",
    year: "May 2023 - September 2023",
    title: "Sales Advisor (Best Buy)",
    desc: "Provided expert guidance and technical support to customers, while maintaining exceptional customer service.",
  },
];

export default Data;
