import React, { useState } from "react";
import "./portfolio.css";
import Menu from "./Menu";
import { Link } from "react-router-dom";

const Portfolio = () => {
  const [items, setItems] = useState(Menu);


  const filterItem = (category) => {
    const filteredItems = Menu.filter((project) =>
      project.categories.includes(category)
    );
    setItems(filteredItems);
  };

  return (
    <section className="work container section" id="work">
      <h2 className="section__title">Recent Works</h2>

      <div className="work__filters">
        <span className="work__item"  onClick={() => setItems(Menu)}>
          All
        </span>
        <span className="work__item" onClick={() => filterItem("C")}>
          C
        </span>
        <span className="work__item" onClick={() => filterItem("Java")}>
          Java
        </span>
        <span className="work__item" onClick={() => filterItem("Python")}>
          Python
        </span>
        <span className="work__item" onClick={() => filterItem("SQL")}>
          SQL
        </span>
        <span className="work__item" onClick={() => filterItem("Mongo")}>
          MongoDB
        </span>
        <span className="work__item" onClick={() => filterItem("Firebase")}>
          Firebase
        </span>
        <span className="work__item" onClick={() => filterItem("React")}>
          React.js
        </span>
        <span className="work__item" onClick={() => filterItem("Express")}>
          Express.js
        </span>
      </div>

      <div className="work__container grid">
        {items.map((elem) => {
          const { id, image, title, categories, link } = elem;
          return (
  
              <div className="work__card shake-horizontal" key={id}>
              <Link to={link} target="_blank">
                <div className="work__thumbnail">
                  <img src={image} alt="" className="work__img" />
                  <div className="work__mask"></div>
                </div>
                </Link>
                <span className="work__category">{categories.join(" / ")}</span>
                <h3 className="work__title">{title}</h3>
                <a href={link} className="work__button">
                  <i className="icon-link work__button-icon"></i>
                </a>
              </div>
      
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
