import Python1 from "../../assets/work-1.svg";
import Java1 from "../../assets/work-3.svg";
import Python3 from "../../assets/work-2.svg";
import Python2 from "../../assets/work-4.jpg";
import Work5 from "../../assets/work-5.svg";
import Java2 from "../../assets/work-6.svg";
import React1 from "../../assets/work-7.svg";
import React2 from "../../assets/work-8.svg";
import React3 from "../../assets/work-9.svg"

const Menu = [
  {
    id: 1,
    image: Python1,
    title: "Anaplan Form Authenticator",
    categories: ["Python"],
    link: "https://github.com/arishsoomar",
  },
  {
    id: 2,
    image: Java1,
    title: "Text Encoder",
    categories: ["Java"],
    link: "https://github.com/arishsoomar/textEncoder",
  },
  {
    id: 3,
    image: Python3,
    title: "Othello Game",
    categories: ["Python"],
    link: "https://github.com/arishsoomar/othello",
  },
  ,
  {
    id: 4,
    image: Python2,
    title: "Autograder",
    categories: ["Python", "SQL"],
    link: "https://github.com/arishsoomar/AutoGrader",
  },
  ,
  {
    id: 5,
    image: Work5,
    title: "Zero Robotics",
    categories: ["C"],
    link: "https://zerorobotics.mit.edu",
  },
  ,
  {
    id: 6,
    image: Java2,
    title: "UnoWar Game",
    categories: ["Java"],
    link: "https://github.com/arishsoomar/unoWar",
  },
  {
    id: 7,
    image: React1,
    title: "Memory Match",
    categories: ["React"],
    link: "/game",
  },
  {
    id: 8,
    image: React2,
    title: "Restaurant Website",
    categories: ["React", "Mongo", "Express"],
    link: "https://github.com/arishsoomar/Restaurant",
  },
  {
    id: 9,
    image: React3,
    title: "Project Management Website",
    categories: ["React", "Firebase"],
    link: "/project",
  },
];

export default Menu;
