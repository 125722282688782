import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import GameApp from "./pages/Game/GameApp"
import ProjectManage from "./pages/ProjectManage/ProjectManage";


import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App></App>,
  },
  {
    path: "/game",
    element: <GameApp></GameApp>
  },
  {
    path: "/project",
    element: <ProjectManage></ProjectManage>
  }
]);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
  <RouterProvider router={router} />
  </React.StrictMode>
);
