import React from 'react'
import './ProjectManage.css'

const ProjectManage = () => {
  return (
    <div className='project-app'>
      <h1>COMING SOON</h1>
    </div>
  )
}

export default ProjectManage
